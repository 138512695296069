/* You can add global styles to this file, and also import other style files */

/********** Template CSS **********/
:root {
  --primary: #003366;
  --secondary: #218efa;
  --light: #F7FAFF;
  --dark: #1D1D27;
}


/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease-out, visibility 0s linear .5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity .5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}


/*** Heading ***/
h1,
h2,
h3,
.fw-bold {
  font-weight: 700 !important;
}

h4,
h5,
h6,
.fw-medium {
  font-weight: 500 !important;
}


/*** Button ***/
.btn {
  font-weight: 500;
  transition: .5s;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 50px;
}


/*** Navbar ***/
.navbar-light .navbar-nav .nav-link {
  position: relative;
  margin-left: 25px;
  padding: 35px 0;
  color: var(--light) !important;
  outline: none;
  transition: .5s;
}

.sticky-top.navbar-light .navbar-nav .nav-link {
  padding: 20px 0;
  color: var(--dark) !important;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
  color: var(--secondary) !important;
}

.navbar-light .navbar-brand h1 {
  color: #FFFFFF;
}

.navbar-light .navbar-brand img {
  max-height: 60px;
  transition: .5s;
  display: none;
}

.sticky-top.navbar-light .navbar-brand img {
  max-height: 100px;
  max-width: 200px;
  display: block;
}

@media (max-width: 991.98px) {
  .sticky-top.navbar-light {
    position: relative;
    background: #FFFFFF;
  }

  .navbar-light .navbar-collapse {
    margin-top: 15px;
    border-top: 1px solid #DDDDDD;
  }

  .navbar-light .navbar-nav .nav-link,
  .sticky-top.navbar-light .navbar-nav .nav-link {
    padding: 10px 0;
    margin-left: 0;
    color: var(--dark) !important;
  }

  .navbar-light .navbar-brand h1 {
    color: var(--primary);
  }

  .navbar-light .navbar-brand img {
    max-height: 100px;
    display: block !important;
  }
}

@media (min-width: 992px) {
  .navbar-light {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    border-bottom: 1px solid rgba(256, 256, 256, .1);
    z-index: 999;
  }

  .sticky-top.navbar-light {
    position: fixed;
    background: #FFFFFF;
  }

  .navbar-light .navbar-nav .nav-link::before {
    position: absolute;
    content: "";
    width: 0;
    height: 2px;
    bottom: -1px;
    left: 50%;
    background: var(--secondary);
    transition: .5s;
  }

  .navbar-light .navbar-nav .nav-link:hover::before,
  .navbar-light .navbar-nav .nav-link.active::before {
    width: 100%;
    left: 0;
  }

  .navbar-light .navbar-nav .nav-link.nav-contact::before {
    display: none;
  }

  .sticky-top.navbar-light .navbar-brand h1 {
    color: var(--primary);
  }
}


/*** Hero Header ***/
.hero-header {
  background: url(../src/assets/img/banniere_img.png) no-repeat center;
  background-size: cover;
  padding-top: 3rem !important;
  padding-bottom: 20rem !important;
}

/*** Hero Header ***/
.ban-image {
  background: url(../src/assets/img/banniere_img.png) no-repeat center;
  background-size: cover;
}


/*** Section Title ***/
.section-title::before {
  position: absolute;
  content: "";
  width: 45px;
  height: 4px;
  bottom: 0;
  left: 0;
  background: var(--dark);
}

.section-title::after {
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  bottom: 0;
  left: 50px;
  background: var(--dark);
}

.section-title.text-center::before {
  left: 50%;
  margin-left: -25px;
}

.section-title.text-center::after {
  left: 50%;
  margin-left: 25px;
}

.section-title h6::before,
.section-title h6::after {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  top: 2px;
  left: 0;
  background: var(--primary);
}

.section-title h6::after {
  top: 5px;
  left: 3px;
}


/*** Service ***/
.service-item {
  position: relative;
  height: 450px;
  padding: 30px 25px;
  background: #FFFFFF;
  box-shadow: 0 0 45px rgba(0, 0, 0, .08);
  transition: .5s;
}

.service-item:hover {
  background: var(--primary);
}

.service-item .service-icon {
  margin: 0 auto 20px auto;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
  transition: .5s;
}

.service-item:hover .service-icon {
  color: var(--light);
}

.service-item h5,
.service-item p {
  transition: .5s;
}

.service-item:hover h5,
.service-item:hover p {
  color: var(--light);
}

.service-item a.btn {
  position: relative;
  display: flex;
  color: var(--primary);
  transition: .5s;
  z-index: 1;
}

.service-item:hover a.btn {
  color: var(--primary);
}

.service-item a.btn::before {
  position: absolute;
  content: "";
  width: 35px;
  height: 35px;
  top: 0;
  left: 0;
  border-radius: 35px;
  background: #DDDDDD;
  transition: .5s;
  z-index: -1;
}

.service-item:hover a.btn::before {
  width: 100%;
  background: var(--light);
}


/*** Testimonial ***/
.newsletter,
.testimonial {
  background-position: left top,
  right bottom;
  background-repeat: no-repeat;
}

.testimonial-carousel .owl-item .testimonial-item,
.testimonial-carousel .owl-item.center .testimonial-item * {
  transition: .5s;
}

.testimonial-carousel .owl-item.center .testimonial-item {
  background: var(--light) !important;
  border-color: var(--light);
}

.testimonial-carousel .owl-item.center .testimonial-item * {
  color: #888888;
}

.testimonial-carousel .owl-item.center .testimonial-item i {
  color: var(--primary) !important;
}

.testimonial-carousel .owl-item.center .testimonial-item h6 {
  color: var(--dark) !important;
}


/*** Team ***/
.team-item {
  position: relative;
  transition: .5s;
  z-index: 1;
}

.team-item::after {
  position: absolute;
  content: "";
  top: 3rem;
  right: 3rem;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  background: #FFFFFF;
  box-shadow: 0 0 45px rgba(0, 0, 0, .1);
  transition: .5s;
  z-index: -1;
}

.team-item:hover::after {
  background: var(--primary);
}

.team-item h5,
.team-item small {
  transition: .5s;
}

.team-item:hover h5,
.team-item:hover small {
  color: var(--light);
}


/*** Project Portfolio ***/
#portfolio-flters .btn {
  position: relative;
  display: inline-block;
  margin: 10px 4px 0 4px;
  transition: .5s;
}

#portfolio-flters .btn::after {
  position: absolute;
  content: "";
  right: -1px;
  bottom: -1px;
  border-left: 20px solid transparent;
  border-right: 0 solid transparent;
  border-bottom: 50px solid #FFFFFF;
}

#portfolio-flters .btn:hover,
#portfolio-flters .btn.active {
  color: var(--light);
  background: var(--primary);
}

.portfolio-overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 30px;
  top: 0;
  left: 0;
  background: var(--primary);
  transition: .5s;
  z-index: 1;
  opacity: 0;
}

.portfolio-item:hover .portfolio-overlay {
  opacity: 1;
}

.portfolio-item .btn {
  position: absolute;
  width: 90px;
  height: 90px;
  top: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  transition: .5s;
  opacity: 0;
  z-index: 2;
}

.portfolio-item:hover .btn {
  opacity: 1;
  transition-delay: .15s;
}


/*** Footer ***/
.footer {
  background: url(../src/assets/img/footer.png) center center no-repeat;
  background-size: contain;
}

.footer .btn.btn-social {
  margin-right: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
  border: 1px solid rgba(256, 256, 256, .1);
  border-radius: 40px;
  transition: .3s;
}

.footer .btn.btn-social:hover {
  color: var(--primary);
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 10px;
  padding: 0;
  text-align: left;
  color: var(--light);
  font-weight: normal;
  transition: .3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .copyright {
  padding: 25px 0;
  font-size: 14px;
  border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright a {
  color: var(--light);
}

.footer .footer-menu a {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, .1);
}

.footer .footer-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

.bg-primary {
  background-color: var(--secondary) !important;
  border-color: #F7FAFF
}

.bg-primary:hover {
  background-color: var(--light) !important;
  color: var(--secondary) !important;
  border-color: #F7FAFF
}

.text-primary {
  color: var(--primary) !important;
}

.btn-primary {
  background-color: var(--primary) !important;
}

.btn-outline-primary {
  color: var(--primary) !important;
}

.btn-outline-primary:hover {
  color: #fff;
}

.btn-secondary {
  background-color: var(--secondary) !important;
}

.btn-color-secondary {
  background-color: var(--secondary) !important;
  color: var(--light) !important;
  font-family: "Berlin Sans FB", sans-serif !important;
}

.pack-item {
  position: relative;
  padding: 30px 25px;
  background: #FFFFFF;
  box-shadow: 0 0 45px rgba(0, 0, 0, .08);
  transition: .5s;
}

.btn-pack {
  width: 400px;
}

.calendly-contact {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 320px;
  height: 880px;
}

@media (max-width: 992px) {
  .row {
    display: block;
  }

  .col-6 {
   width: 100%;
  }
}

html {
  scroll-behavior: smooth;
}





